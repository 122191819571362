import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as ackeeTracker from 'ackee-tracker';
import SearchAppBar from './components/SearchAppBar';
import Welcome from './components/Welcome';
import Search from './components/Search';
import Favorites from './components/Favorites';
import Recents from './components/Recents';
import Settings from './components/Settings';
import About from './components/About';

function App() {
    ackeeTracker.create('https://analytics.kosmonaft.dev', {
        /*
         * Enable or disable tracking of personal data.
         * We recommend to ask the user for permission before turning this option on.
         */
        detailed: true,
        /*
         * Enable or disable tracking when on localhost.
         */
        ignoreLocalhost: true,
        /*
         * Enable or disable the tracking of your own visits.
         * This is enabled by default, but should be turned off when using a wildcard Access-Control-Allow-Origin header.
         * Some browsers strictly block third-party cookies. The option won't have an impact when this is the case.
         */
        ignoreOwnVisits: true
    }).record('61518f26-74ac-45f8-8f43-3c418b20745a');

    return (
      <Router>
          <SearchAppBar/>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/search" component={Search} />
          <Route exact path="/favorites" component={Favorites} />
          <Route exact path="/recents" component={Recents} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/about" component={About} />
      </Router>
  );
}

export default App;
