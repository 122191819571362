/**
 * @author Alexandre DEBUSSCHERE <alexandre@common-services.com>
 */

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from '@material-ui/core/IconButton';
import IconNext from '@material-ui/icons/ChevronRight';
import {Link} from "react-router-dom";
import vocab from '../data/vocab';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        flexGrow: 1,
        textAlign: 'center'
    },
    item: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    }
});

function Welcome(props) {
    const {classes} = props;
    const [randoms, setRandoms] = useState([]);

    useEffect(() => {
        const list = [
            vocab[~~(vocab.length * Math.random())],
            vocab[~~(vocab.length * Math.random())],
            vocab[~~(vocab.length * Math.random())],
            vocab[~~(vocab.length * Math.random())],
            vocab[~~(vocab.length * Math.random())],
            vocab[~~(vocab.length * Math.random())]
        ].map(word => (word.en || '').split(';')[0].trim());

        setRandoms(list)
    }, []);

    return (
        <div>
            <Grid container justify="center" className={classes.root}>
                <Grid item xs={12}>
                    <Typography component="h1">
                        English, French and Thai dictionary, all in one place !
                    </Typography>
                </Grid>
            </Grid>
            <Grid container direction="row" justify="center" alignItems="stretch" className={classes.root}>
                {randoms.length ? randoms.map((result, key) => {
                    return (
                        <Grid item xs={12} md={4} lg={6} key={key}>
                            <List component="ul" className={classes.item}>
                                <Link
                                    to={{
                                        pathname: '/search',
                                        search: '?word=' + result,
                                    }}
                                    className={classes.link}
                                >
                                    <ListItem component="li">
                                        <ListItemText
                                            primary={result}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" aria-label="Delete">
                                                <IconNext />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </Link>
                            </List>
                        </Grid>
                    );
                }) : <CircularProgress className={classes.progress} />}
            </Grid>
        </div>
    );
}

Welcome.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Welcome);
