/**
 * @author Alexandre DEBUSSCHERE <alexandre@common-services.com>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

require('../style.css');

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        flexGrow: 1
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.common.blue,
        cursor: 'pointer'
    }
});

function About(props) {
    const {classes} = props;

    return (
        <div>
            <Grid container justify="center" className={classes.root}>
                <Grid item xs={12}>
                    <Typography component="p">
                        A simple yet useful Thai, English and French dictionary. Perfect to travel as it does not require an
                        internet connection to work.<br/>
                        Indeed, you can add this page as an application on your Android phone via the Google Chrome web browser.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justify="flex-start" className={classes.root}>
                <Grid item xs={12}>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Version"
                                secondary="1.0.05"
                            />
                            <ListItemText
                                primary="Words"
                                secondary="5161"
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Built with"
                                secondary="React"
                            />
                            <ListItemText
                                primary="Packages"
                                secondary={<span>
                                react-router-dom<br/>
                                material-ui<br/>
                                react-country-flag
                            </span>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Database"
                                secondary="Volubilis"
                            />
                            <ListItemText
                                primary="Website"
                                secondary={<a href="http://belisan-volubilis.blogspot.com/" target="_blank" className={classes.link} rel="noopener noreferrer">
                                        http://belisan-volubilis.blogspot.com
                                </a>}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="By"
                                secondary="Alexandre DEBUSSCHERE"
                            />
                            <ListItemText
                                primary="Website"
                                secondary={<span id="alexlinks">
                                <a href="https://debuss-a.me" target="_blank" className={classes.link} rel="noopener noreferrer">
                                    https://debuss-a.me
                                </a><br/>
                                <a href="https://github.com/debuss" target="_blank" className={classes.link} rel="noopener noreferrer">
                                    https://github.com/debuss
                                </a>
                            </span>}
                            />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </div>
    );
}

About.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);
