/**
 * @author Alexandre DEBUSSCHERE <alexandre@common-services.com>
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Typography from '@material-ui/core/Typography';
import ReactCountryFlag from 'react-country-flag';
import vocab from '../data/vocab';
import Env from '../Env';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        flexGrow: 1
    },
    card: {
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    pos: {
        marginBottom: 12
    },
    list: {
        paddingTop: 12,
        paddingBottom: 12
    },
    progress: {
        margin: theme.spacing(2),
    }
});

function Search(props) {
    const {classes} = props;
    const flagStyle = {verticalAlign: 'middle', width: '50px', height: '30px', marginRight: '5px'};
    const word = new URLSearchParams(decodeURIComponent(window.location.search)).get('word').toLowerCase();
    const [progress, setProgress] = useState(true);
    const [results, setResults] = useState([]);

    useEffect(() => {
        const regex = new RegExp('\\b' + word + '\\b', 'g');
        const results = vocab.filter((search) => {
            return regex.test((search.en + '|' + search.fr + '|' + search.th).toLowerCase());
        });

        if (results.length) {
            Env.saveToRecents(word, results);
        }

        setResults(results);
        setProgress(false);
    }, [word]);

    if (progress) {
        return (
            <Grid container direction="row" justify="center" alignItems="stretch" className={classes.root}>
                <CircularProgress className={classes.progress} />
            </Grid>
        );
    }

    return (
        <Grid container direction="row" justify="center" alignItems="stretch" className={classes.root}>
            {results.length ? results.map((result, key) => {
                return (
                    <Grid key={key} item component={Card} xs={12} md={5} lg={3} className={classes.card}>
                        <CardContent>
                            <Typography className={classes.pos} color="textSecondary">
                                {result.type}
                            </Typography>

                            <List component="ul">
                                <ListItem component="li" className={classes.list}>
                                    <ListItemAvatar>
                                        <ReactCountryFlag styleProps={flagStyle} code="gb" svg />
                                    </ListItemAvatar>
                                    <ListItemText primary={result.en} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem component="li" className={classes.list}>
                                    <ListItemAvatar>
                                        <ReactCountryFlag styleProps={flagStyle} code="fr" svg />
                                    </ListItemAvatar>
                                    <ListItemText primary={result.fr} />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                                <ListItem component="li" className={classes.list}>
                                    <ListItemAvatar>
                                        <ReactCountryFlag styleProps={flagStyle} code="th" svg />
                                    </ListItemAvatar>
                                    <ListItemText primary={result.th} secondary={result.thairom} />
                                </ListItem>
                            </List>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="primary" onClick={() => {Env.saveToFavorites(word, result)}}>
                                <FavoriteIcon /> Add to favorites
                            </Button>
                        </CardActions>
                    </Grid>
                );
            }) : <Typography>No results...</Typography>}
        </Grid>
    );
}

Search.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Search);
