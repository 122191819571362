import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import IconNext from '@material-ui/icons/ChevronRight';
import {Link} from "react-router-dom";
import Env from '../Env';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        flexGrow: 1
    },
    item: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    }
});

function Favorites(props) {
    const {classes} = props;
    const favorites = Env.getFavorites();

    return (
        <Grid container direction="row" justify="flex-start" alignItems="stretch" className={classes.root}>
            {favorites.length ? favorites.map((result, key) => {
                return (
                    <Grid item xs={12} md={4} lg={6} key={key}>
                        <List className={classes.item}>
                            <Link
                                to={{
                                    pathname: '/search',
                                    search: '?word=' + result,
                                }}
                                className={classes.link}
                            >
                                <ListItem>
                                    <ListItemText
                                        primary={result}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="Delete">
                                            <IconNext />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Link>
                        </List>
                    </Grid>
                );
            }) : 'No favorites word for now...'}
        </Grid>
    );
}

Favorites.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Favorites);
