/**
 * @author Alexandre DEBUSSCHERE <alexandre@common-services.com>
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Env from '../Env';

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(1),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        flexGrow: 1
    },
    item: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
});

function useForceUpdate()
{
    const [value, set] = useState(true);
    return () => set(!value);
}

function Settings(props) {
    const {classes} = props;
    const forceUpdate = useForceUpdate();

    const sizes = Env.getStorageSizes();

    return (
        <Grid container direction="row" justify="flex-start" alignItems="stretch" className={classes.root}>
            <Grid item xs={12}>
                <List className={classes.item}>
                    <ListItem>
                        <ListItemText
                            primary="Clear Recent History"
                            secondary={sizes.recents + ' kB'}
                        />
                        <ListItemSecondaryAction>
                            <Button size="small" color="primary" onClick={() => {
                                Env.clearRecentsCache();
                                forceUpdate();
                            }}>
                                <DeleteIcon />
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12}>
                <List className={classes.item}>
                    <ListItem>
                        <ListItemText
                            primary="Clear Saved Favorites"
                            secondary={sizes.favorites + ' kB'}
                        />
                        <ListItemSecondaryAction>
                            <Button size="small" color="primary" onClick={() => {
                                Env.clearFavoritesCache();
                                forceUpdate();
                            }}>
                                <DeleteIcon />
                            </Button>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
}

Settings.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
