/**
 * @author Alexandre DEBUSSCHERE <alexandre@common-services.com>
 */

export default class Env {

    static saveToRecents(word, results) {
        try {
            localStorage.setItem('recents', JSON.stringify({
                ...JSON.parse(localStorage.getItem('recents')) || {},
                [word]: results
            }));
        } catch (e) {
        }
    }

    static saveToFavorites(word, results) {
        try {
            localStorage.setItem('favorites', JSON.stringify({
                ...JSON.parse(localStorage.getItem('favorites')) || {},
                [word]: results
            }));
        } catch (e) {
        }
    }

    static getRecents() {
        return Object.keys(JSON.parse(localStorage.getItem('recents')) || {});
    }

    static getFavorites() {
        return Object.keys(JSON.parse(localStorage.getItem('favorites')) || {});
    }

    static getStorageSizes() {
        let sizes = {
            recents: 0,
            favorites: 0
        };
        let _xLen, _x;

        for (_x in localStorage) {
            if (!localStorage.hasOwnProperty(_x)) {
                continue;
            }

            _xLen = ((localStorage[_x].length + _x.length) * 2);
            sizes = {
                ...sizes,
                [_x]: (_xLen / 1024).toFixed(2)
            }
        }

        return sizes;
    }

    static clearRecentsCache() {
        try {
            localStorage.removeItem('recents');
        } catch (e) {
            return false;
        }

        return true;
    }

    static clearFavoritesCache() {
        try {
            localStorage.removeItem('favorites');
        } catch (e) {
            return false;
        }

        return true;
    }

}
